.App {
  text-align: center;
  min-height: 100vh;
  background-color: #f5f5f5;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.navbar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  width: 100%;
  box-sizing: border-box;
}

.navbar-others {
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  width: 100%;
  box-sizing: border-box;
  position: relative;
}

/* Remove backdrop filter and hover effect */
.navbar:hover {
  background-color: transparent;
}

/* Update text color for better visibility on transparent background */
.navbar .MuiTypography-root {
  color: #1F2937;
  font-weight: 800;  /* Made bolder for better visibility */
  font-size: 1.5rem;
  letter-spacing: -0.5px;
  margin: 0;
  padding: 0;
  text-shadow: 0 2px 4px rgba(255, 255, 255, 0.5);  /* Added shadow for better readability */
}

/* Update button style for better visibility */
.navbar .MuiButton-root {
  background-color: #ff3635;
  color: white;
  font-weight: 600;
  padding: 0.5rem 1.5rem;
  border-radius: 8px;
  height: 40px;
  min-width: 100px;
  text-transform: none;
  font-size: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);  /* Added subtle shadow */
}

.logo {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
  height: 70px;
  cursor: pointer;
}

.blog-button {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  background-color: #ff007b;
  color: white;
  cursor: pointer;
}

.main-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.hero-section {
  position: relative;
  min-height: 65vh;
  width: 100%;
  overflow: hidden;
  background: 
    linear-gradient(10deg, 
      transparent 45%,
      #ffffff 45.1%
    ),
    linear-gradient(90deg,
      #7928CA 0%,
      #FF0080 33%,
      #7928CA 66%,
      #FF0080 100%
    );
  background-size: 100% 100%, 300% 100%;
  animation: gradientAnimation 15s ease infinite;
  padding-top: 80px;
  margin: 0;
  width: 100vw;
  box-sizing: border-box;
}

.hero-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 3rem 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 1;
  gap: 4rem; /* Add gap between text and images */
}

.hero-text {
  flex: 1;
  max-width: 500px;  /* Slightly reduced from 600px */
  padding-right: 0;  /* Remove right padding */
  margin-left: 0; /* Increased left margin to move text more to the left */
}

.hero-text h1 {
  font-size: 3rem;
  text-align: left;
  font-weight: 800;
  color: #1F2937;
  margin-bottom: 1.5rem;
  line-height: 1.2;
  text-shadow: none;
}

.hero-text p {
  font-size: 1.25rem;
  text-align: left;
  color: #f4f6fa;
  margin-bottom: 2rem;
  line-height: 1.6;
  text-shadow: none;
}

.cta-button {
  background-color: #ff3635;
  color: white;
  padding: 1rem 2rem;
  border: none;
  border-radius: 50px;
  font-size: 1.125rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  display: block;
  margin-left: 0;
  text-align: center;
  width: fit-content;
}

.cta-button:hover {
  background-color: #6820b0;
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.15);
}

.hero-images {
  flex: 1;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 325px;
}

.sample-image {
  position: absolute;
  width: 200px;
  height: 300px;
  border-radius: 12px;
  box-shadow: 0 8px 16px -4px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
  object-fit: cover;
}

.sample-image:nth-child(1) {
  transform: rotate(-13deg) translateX(-161px) translateY(-43px);
  z-index: 1;
}

.sample-image:nth-child(2) {
  transform: translateY(-30px);
  z-index: 2;
}

.sample-image:nth-child(3) {
  transform: rotate(13deg) translateX(161px) translateY(-43px);
  z-index: 1;
}

.sample-image:nth-child(1):hover {
  transform: rotate(-13deg) translateX(-161px) translateY(-43px) scale(1.1);
  z-index: 3;
}

.sample-image:nth-child(2):hover {
  transform: translateY(-40px) scale(1.1);
  z-index: 3;
}

.sample-image:nth-child(3):hover {
  transform: rotate(13deg) translateX(161px) translateY(-43px) scale(1.1);
  z-index: 3;
}

/* Remove the general hover effect */
.sample-image:hover {
  z-index: 3;
}

@media (max-width: 768px) {
  .navbar {
    height: 60px;
    padding: 0 1.5rem;
  }
  
  .main-content {
    padding: 1rem;
  }
  
  .hero-content {
    padding: 2rem 1.5rem;
    flex-direction: column;
    text-align: center;
  }

  .hero-text {
    margin-left: 0;
  }

  .hero-images {
    margin-right: 0;
  }

  .hero-text h1 {
    font-size: 2.5rem;
  }

  .hero-images {
    min-height: 225px;
    margin-top: 2rem;
  }

  .sample-image {
    width: 140px;
    height: 200px;
  }

  .sample-image:nth-child(1) {
    transform: rotate(-11deg) translateX(-93px) translateY(-27px);
  }

  .sample-image:nth-child(2) {
    transform: translateY(-20px);
  }

  .sample-image:nth-child(3) {
    transform: rotate(11deg) translateX(93px) translateY(-27px);
  }

  .sample-image:nth-child(1):hover {
    transform: rotate(-11deg) translateX(-93px) translateY(-27px) scale(1.1);
  }

  .sample-image:nth-child(2):hover {
    transform: translateY(-30px) scale(1.1);
  }

  .sample-image:nth-child(3):hover {
    transform: rotate(11deg) translateX(93px) translateY(-27px) scale(1.1);
  }

  .hero-section {
    padding-top: 60px;
    min-height: 55vh;
  }

  .cta-button {
    margin: 0 auto;
  }
}

.template-showcase {
  width: 100%;
  padding: 4rem 0;
  background-color: #d7c6d7;
  overflow: hidden;
}

.template-showcase .container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 1rem;
}

.template-showcase h2 {
  font-size: 2.5rem;
  color: #1F2937;
  margin-bottom: 1rem;
  font-weight: 700;
  text-align: center;
}

.template-showcase p {
  font-size: 1.125rem;
  color: #4B5563;
  max-width: 1000px;
  margin: 0 auto 3rem;
  text-align: center;
  line-height: 1.6;
}

/* Make slider container take full viewport width */
.template-showcase .slick-slider {
  width: 100vw;
  margin-left: calc(-50vw + 50%);
  margin-right: calc(-50vw + 50%);
}

/* Adjust slide sizes to be smaller */
.template-showcase .slick-slide {
  padding: 0 1rem;
  transform: scale(0.85);
  transition: transform 0.3s ease;
}

.template-showcase .slick-current {
  transform: scale(1);
}

.template-showcase .slick-slide img {
  max-height: 500px;
  width: auto;
  margin: 0 auto;
  border-radius: 8px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

@media (max-width: 768px) {
  .template-showcase {
    padding: 2rem 0;
  }

  .template-showcase h2 {
    font-size: 2rem;
    padding: 0 1rem;
  }

  .template-showcase p {
    font-size: 1rem;
    padding: 0 1rem;
  }

  .template-showcase .slick-slide {
    padding: 0 0.5rem;
  }

  .template-showcase .slick-slide img {
    max-height: 400px;
  }
}

.circle-number {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: red;
  color: white;
  font-size: 24px;
  box-shadow: 0 0 10px rgba(255, 0, 0, 0.5);
  margin: 0 auto;
}

@media (max-width: 600px) {
  .navbar, .navbar-others {
    padding: 1rem;
  }
  
  .logo {
    height: 55px;
    padding-top: 9px;
  }
}

/* Add this rule to set the default font */
body {
  font-family: 'Noto Sans Display', sans-serif;
}

/* Apply the font to all elements, including Material-UI Typography */
* {
  font-family: 'Noto Sans Display', sans-serif !important; /* Use !important to override other styles */
}

/* Update sale banner to be fixed at bottom with red glowing text */
.sale-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background: #ff3635;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  box-shadow: 0 -4px 15px rgba(255, 0, 0, 0.2);
}

.sale-banner-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
}

.sale-banner-content h6 {
  font-weight: 700;
  font-size: 18px;
  margin: 0;
  color: black;
  text-shadow: 0 0 10px rgba(255, 54, 53, 0.7);
  animation: glowEffect 1.5s infinite alternate;
}

.sale-banner-content p {
  font-weight: 500;
  font-size: 14px;
  margin: 0;
  color: black;
  font-size: 16px; /* Adjusted for better visibility */
}

.sale-banner-close {
  position: absolute !important;
  right: 16px;
  color: #ff3635 !important;
}

@keyframes glowEffect {
  from {
    text-shadow: 0 0 5px rgba(255, 54, 53, 0.7);
  }
  to {
    text-shadow: 0 0 15px rgba(255, 54, 53, 0.9), 0 0 20px rgba(255, 54, 53, 0.5);
  }
}

@media (min-width: 768px) {
  .sale-banner-content {
    flex-direction: row;
    gap: 12px;
  }
  
  .sale-banner-content h6:after {
    content: ' —';
  }
}
