.template-slider-container {
  padding: 20px 0;
}

.template-swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.swiper-button-next,
.swiper-button-prev {
  color: #333;
}

@media (max-width: 768px) {
  .swiper-slide {
    width: 100% !important;
  }
} 